import React, { useEffect, useState } from 'react';
import './App.css';
import logo from './Qafoor1.png';

const ComingSoon = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots.length >= 3) {
          return '';
        } else {
          return prevDots + '.';
        }
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="coming-soon-container">
      <div className="logo-container">
        <img src={logo} alt="Qafoor Logo" className="logo" />
      </div>
      <div className="coming-soon-content">
        <h1 className="coming-soon-title">Coming Soon<span className="moving-dots">{dots}</span></h1>
        <p className="coming-soon-text">Stay tuned for something amazing!</p>
      </div>
    </div>
  );
};

export default ComingSoon;
